import { Link, NavLink, useNavigate } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="cSecTwo">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <h2 className="footer-widget__title">Important Links</h2>
                <ul className="list-unstyled footer-widget__link-list">
                  <li>
                    <a href="/get-started" className="ftrLink">Home</a>
                  </li>
                  <li>
                    <a href="/get-started#how-it-works" className="ftrLink">How It Works</a>
                  </li>
                  <li>
                    <a href="/get-started#why-gotosample" className="ftrLink">Why Us</a>
                  </li>
                  <li>
                    <a href="/login" className="ftrLink">Login</a>
                  </li>
                  <li>
                    <a href="/get-started" className="ftrLink">Join Us</a>
                  </li>
                </ul>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <h2 className="footer-widget__title">Quick Links</h2>
                <ul className="list-unstyled footer-widget__link-list">
                  <li>
                    <Link to="/privacy-notice" className="ftrLink" target="_blank">
                      Privacy Notice
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms&condition" className="ftrLink" target="_blank">
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq" className="ftrLink" target="_blank">
                      FAQs
                    </Link>
                  </li>
                </ul>
                <br />
                <h2 className="footer-widget__title">Contact Us</h2>
                <ul className="list-unstyled footer-widget__link-list">
                  <li>
                    <a href="mailto:notification@gotosample.com" className="ftrLink">notification@gotosample.com</a>
                  </li>
                </ul>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <h2 className="footer-widget__title">Follow Us</h2>
                <ul className="list-unstyled ftrSocial">
                  <li className="twitter-x"><a href="https://x.com/mindforcer?s=11" target="_blank"><img src="/home/front/assets/images/twitter-x.png" width="15" alt="Twitter - X" /></a></li>
                  <li className="socialIcons"><a href="https://www.facebook.com/mindforceresearch" target="_blank"><i className="fab fa-facebook"></i></a></li>
                  <li className="socialIcons"><a href="https://www.linkedin.com/company/mindforceresearch/" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                  <li className="socialIcons"><a href="https://instagram.com/mindforce_research?igshid=MTNiYzNiMzkwZA==" target="_blank"><i className="fab fa-instagram"></i></a></li>
                </ul>
              </div>
              <div className="col-lg-6 ftrLine">
                <img
                      src="/home/front/assets/images/gotosample-logo.png"
                      width="100"
                      alt="gotosample"
                    />
              </div>
              <div className="col-lg-6 ftrLine">
                <p className="ft-copy alnR">
                  Copyright 2025{" "}gotosample.{" "}All Rights Reserved.
                </p>
              </div>

            </div>
          </div>
        </footer>
    </>
  )
}

export default Footer;